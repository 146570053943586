import React from 'react';
import ReactDOM from 'react-dom';
import ErrorHandling from 'components/Gunbot/ErrorHandling';
import App from './App';
import { CreateSocket } from './SocketIO';
import AppProviders from 'providers/AppProviders';
import store from 'redux/store';
import { setLoading } from 'redux/actions';

import 'assets/css/themes.css';
import 'index.css';

React.Component.prototype.generateState = function (data = []) {
  const state = {};
  data.forEach(item => {
    if (item.children) {
      state[item.name] = this.generateState(item.children);
    } else {
      state[item.name] = item.value;
    }
  });
  return state;
};

React.Component.prototype.setValue = function (value, key, ...keys) {
  keys = keys.reverse();
  let data = this.state;
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] != null) {
      if (data[keys[i]] === undefined) data[keys[i]] = {};
      data = data[keys[i]];
    }
  }
  data[key] = value;
  this.setState(this.state);
};

React.Component.prototype.reGetValue = function (key, ...parents) {
  parents = parents.reverse();
  let data = this.state;
  parents.forEach(key => {
    if (key != null) {
      if (data[key] === undefined) {
        data[key] = {};
      }
      data = data[key];
    }
  });
  return data[key];
};

React.Component.prototype.getValue = function (key, ...parents) {
  parents = parents.reverse();
  let data = this.state;
  let isUndefined = false;
  parents.forEach(key => {
    if (key != null) {
      if (data[key] === undefined) {
        data[key] = {};
        isUndefined = true;
      }
      data = data[key];
    }
  });

  if (isUndefined || data[key] === undefined) {
    this.state = this.generateState(this.props.data);
    return this.reGetValue(key, ...parents);
  }

  return data[key];
};

React.Component.prototype.loading = function (loading) {
  store.dispatch(setLoading(loading));
};

ReactDOM.render(
  <ErrorHandling>
    <CreateSocket>
      <AppProviders>
        <App />
      </AppProviders>
    </CreateSocket>
  </ErrorHandling>,
  document.getElementById('root'),
);
